const fontSizes = {
  sm: "1em",
  md: "3em",
  lg: "4em",
};

const colors = {
  primary: "#000",
  secondary: "#fff",
  background: "#ffffcc",
};

const dimensions = {
  xMin: 5,
  xMax: 80,
  yMin: 10,
  yMax: 50,
  tMin: -12,
  tMax: 12,
};

const theme = {
  colors,
  fontSizes,
  dimensions,
};

export default theme;
