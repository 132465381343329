import { createGlobalStyle } from "styled-components";
import theme from "./theme";
import logo from "../images/cursor.png";

export type Props = {
  theme: typeof theme;
};

const GlobalStyles = createGlobalStyle<Props>`

@import url('https://fonts.googleapis.com/css2?family=Fahkwang:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
 
:root {
  font-size: 1rem;
  font-family: 'Fahkwang', sans-serif;
 }

* {
  cursor: url(${logo}) 40 50, auto;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
p,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
li,
figcaption {
  max-width: 70ch;
}

html {
  scroll-behavior: smooth;
 }
 
 body {
  background: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.primary};
  font-family: 'Fahkwang', sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 }

 p {
  line-height: 1.5;
}

a {
  color: currentColor;
  font-weight: bold;
  text-decoration: none;
}

 button {
  background: none;
  border: none;
 }

 img {
  border: 1px solid #000;
 }

 @media only screen and (min-width: 2500px) {
  :root {
    font-size: 2rem;
   }
}
`;

export default GlobalStyles;
